.table {
    width: 100%;
    .img-table {
        display: inline;
    }
    img {
        max-width: 100%;
    }
    
    .fotter-table {
        display: flex;
        border-style: solid;
        border-width: 1px;
        padding: 4px;
    }
    
    .titletable {
        // margin-right: 65px;
        font-size: 14px;
        color: #00072B;
    }
    .row-title {
        margin-top: 8px;
        border-bottom: solid 1px;
        border-color: #ECF0F2;
        padding: 10px;
    }

    .row-table {
        // margin-top: 8px;
        border-bottom: solid 1px;
        border-color: #ECF0F2;
        // padding: 10px;
    }

    .matn-of-table {
        // margin-top: 10px;
        font-size: 14px;
        color: #00072B;
        // margin-right: 13px;
    }

    .imges-last-sell-of-table {
        margin-top: 12px;
        width: 14px;
        height: 8px;
        position: absolute;
        left: 0px;
    }

    .line {
        width: 100%;
        height: 1px;
        background-color: #ECF0F2;
        margin-top: 20px;
    }
}