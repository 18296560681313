/* * {
    margin:0;
    padding:0;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;
@import "../assets/fotns/webFonts/css/fontiran.css";
body {
    text-align: right;
    direction: rtl;
    font-family: "IRANSans" !important;
    background-color: #f5f5f5 !important;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
tspan,
button {
    font-family: "IRANSans" !important;
}

tspan {
    font-size: 8px!important;
}